import React from 'react';
import './Privacy.css';

function Privacy() {
  return (
    <div  className='policy-container'>
      <h1>Privacy Policy</h1>
      <div className='policy-content'>
        <div className='policy-section'>
          <div className='policy-text'>
            We want you to know how CAPSULE uses and protects any information that you give when you use our website or our mobile application (our “App”). CAPSULE is committed to ensuring that your privacy is protected, it’s something we take very seriously.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>What information do we collect?</div>
          <div className='policy-text'>
            We collect:
            <div className='policy-list'>
              <ul>
                <li>Technical Information (browser, the operating system of the device, the length of time on the App, user activity on the App, etc.)</li>
                <li>Personal Information (first name, phone number, profile information)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>What do we do with the information we collect?‍</div> 
          <div className='policy-text'>
            We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
          </div>
          <div className='policy-list'>
            <ul>
              <li>To personalize your experience (your information helps us to respond to your individual needs)</li>
              <li>To improve our website and product (we continually strive to improve the App based on your feedback)</li>
              <li>To provide support and respond to questions and requests for information</li>
              <li>To understand and analyze the trends and preferences of our Users (we use Google Analytics for data analytics)</li>
              <li>To operate and improve the Services, to develop new products, services, features, and functionalities, and to alert you of events and products of interest to you</li>
            </ul>
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>Security</div>
          <div className='policy-text'>
            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>Cookies</div>
          <div className='policy-text'>
            A cookie is a small file of information placed on your computer. Cookies can help track which websites you visit and what you do on the site.CAPSULE uses cookies for the following purposes:• To understand site analytics to improve site features, tailor content, and enhance user experience
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>Links to other websites</div>
          <div className='policy-text'>
            Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>Do we disclose any information to outside parties?‍</div>
          <div className='policy-text'>
            We will not sell, distribute, or lease your personal information to unrelated third parties unless we have your permission or are required by law to do so. We may need to send your personal information to our third-party vendors, who are under contractual obligations to keep this information confidential. We may also use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen. Finally, if we ever sell CAPSULE, or participate in a merger or consolidation, and our User data is one of the assets involved in the sale, your personal information will be part of this sale or transfer.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>
            We may also disclose your personal information:
          </div>
          <div className='policy-text'>
            <ul>
              <li>To comply with any court order, law or legal process, including to respond to any government or regulatory request</li>
              <li>To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes</li>
              <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of CAPSULE, our customers, or others. This may include exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>
            Your consent
          </div>
          <div className='policy-text'>
            By using our site, you consent to our privacy policy.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>
            Children Under The Age of 13
          </div>
          <div className='policy-text'>
            Our Website is not intended for children under 13 years of age. No one under the age of 13 may provide any personal information to, or on, the Website. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Website, or on or through any of its features/functionality, make any purchases through the Website, use any of the interactive or public comment features that may be available on this Website, or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or username you may use. If we learn that we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please 
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>
            Do Not Track
          </div>
          <div className='policy-text'>
            Do Not Track is a preference you can set in your browser to let websites you visit know that you do not want them collecting certain information about you. We do not currently respond to, or honor, Do Not Track signals or requests from your browser.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>
            Accessing and Correcting Your Information‍
          </div>
          <div className='policy-text'>
            You can review and change your personal information by logging into your profile through the website or App and visiting your account profile page or settings page.You may also send us an e-mail at hello@shopapsule.ai to request access to, correct or delete any personal information that you have provided to us. We may not be able to delete your personal information except by also deleting your user account. We also may not accommodate a request to change information if we believe the change would violate any law or legal requirement, or cause the information to be incorrect.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>
            Notice to Users from the European Economic Area
          </div>
          <div className='policy-text'>
            If you are visiting and using the Services from the European Economic Area (“EEA”), please be aware that you are sending information (including personal data) to the United States where our partner platform’s servers are located. Our staff operating outside the EEA may process this information. The information may then be transferred within the United States or back out of the United States to other countries outside of your country of residence, depending on the type of information and how we store it. These countries (including the United States) may not necessarily have data protection laws as comprehensive or protective as those in your country of residence; however, our collection, storage and use of your personal data will at all times continue to be governed by this Privacy Policy.By supplying your personal information to us you expressly agree to the transfer of your personal information out of the EEA, and to the processing of your information in the U.S., subject to this Privacy Policy.
          </div>
        </div>
        <div className='policy-section'>
          <div className='policy-header'>Changes to our privacy policy</div>
          <div className='policy-text'>
            If we decide to change our privacy policy, we will post those changes on this page.
          </div>
          <div className='policy-text'>
            Questions? Email us at hello@shopcapsule.ai
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;