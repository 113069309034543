import React, { useState } from 'react';
import './Games.css';
import confetti from 'canvas-confetti'; // Import the confetti library
import { useNavigate } from 'react-router-dom';


const Games = () => {
  const navigate = useNavigate();

  const imageSets = [
    {
      mainImage: 'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-3.png',
      images: [
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-10.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-1.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-4.png',
      ],
    },
    {
      mainImage: 'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/black-dress-1.png',
      images: [
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-2.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/black-dress-1.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-3.png',
      ],
    },
    {
      mainImage: 'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-2.png',
      images: [
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-3.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-10.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/black-dress-1.png',
      ],
    },
    {
      mainImage: 'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-11.png',
      images: [
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-2.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-10.png',
        'https://capsule-external-games-production.s3.us-east-2.amazonaws.com/red-dress-3.png',
      ],
    },
  ];
  const [currentSet, setCurrentSet] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);

  const toggleSelectImage = (imageIndex) => {
    const isSelected = selectedImages.includes(imageIndex);
    if (isSelected) {
      setSelectedImages(selectedImages.filter((i) => i !== imageIndex));
    } else {
      setSelectedImages([...selectedImages, imageIndex]);
    }
  };

  const handleNextSet = () => {
    if (currentSet < imageSets.length - 1) {
      setCurrentSet(currentSet + 1);
      setSelectedImages([]);
      
    } else {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

      console.log("Selected images:", selectedImages);

      window.gtag('event', 'submit_images', {
        'event_category': 'Games',
        'event_label': 'Image Selection',
        'value': selectedImages.length 
      });

      setTimeout(() => {
        navigate('/');
    }, 1000); 
    }
  };

  return (
    <div className="recaptcha-style">
      <div className="main-content">
        <h3>Love fashion? Help our algorithms get better!</h3>
  
        <div className="main-image">
          <img src={imageSets[currentSet].mainImage} alt="Main" className="main-image-responsive" />
        </div>
        <p>Select the most similar looking dress:</p>
        <div className="image-grid">
          {imageSets[currentSet].images.map((image, index) => (
            <div
              key={index}
              className={`grid-item ${selectedImages.includes(index) ? 'selected' : ''}`}
              onClick={() => {
                toggleSelectImage(index);
                handleNextSet();
              }}
            >
              <img src={image} alt={`Gallery ${index + 1}`} className="gallery-image-responsive" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Games;