import React, { useState } from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import Privacy from './Privacy';
import Games from './Games';
import Search from './Search';
import Analysis from './Analysis';
import mixpanel from "mixpanel-browser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBars } from '@fortawesome/free-solid-svg-icons';

import './App.css';
import logo from './logo.png';
import tiktok from './tiktok.png';
import instagram from './instagram.png';

function App() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };


  useEffect(() => {
    mixpanel.init("580b461c1aa93c4d7d79d958ee2dc945", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });

  }, []);

  return (
    <Router>
      <div className="App">
        <div id="navigation-container">
          <Link to="/" className="logo-container">
            <img className="logo" src={logo} alt="Logo" />
          </Link>
          <div>
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              <FontAwesomeIcon icon={faBars} className="hamburger-icon" />
            </button>
            <div className={`links-container ${isDropdownOpen ? 'open' : ''}`}>
              <div className="social-media-link">
                <Link to="/your-color-closet" onClick={closeDropdown}>Find your Color Seasons</Link><span className="new-tag">New</span>
              </div>
              <div className="social-media-link" onClick={closeDropdown}>
                <Link to="/color-search">Shop by Color</Link><span className="new-tag">New</span>
              </div>
              <div className="social-media-link" onClick={closeDropdown}>
                <Link to="/games">Games</Link>
              </div>
              <div className="social-media-link" onClick={closeDropdown}>
                <a href="https://www.instagram.com/shopcapsule.ai"  target="_blank" rel="noopener noreferrer" className="social-media-button">
                  <img className="instagram" src={instagram} alt="Logo" />
                </a>
              </div>
              <div className="social-media-link" onClick={closeDropdown}>
                <a href="https://www.tiktok.com/@shopcapsule.ai"  target="_blank" rel="noopener noreferrer" className="social-media-button">
                  <img className="tiktok" src={tiktok} alt="Logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/games" element={<Games />} />
          <Route path="/color-search" element={<Search />} /> 
          <Route path="/your-color-closet" element={<Analysis />} /> 

        </Routes>
        <footer className="sticky-footer">
          <div id="footer-container">
            <div className="rights-reserved-container">
              © 2024 CAPSULE. All rights reserved.
            </div>
            <div className="footer-links-container">
              <Link to="/privacy" className="privacy-link">Privacy</Link> 
              <Link to="/contact" className="contact-link">Contact</Link>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
