import React from 'react';
import mockup from './mockup.png';
import './Home.css';
import apple from './apple.png';

function Home() {
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  return (
    <div>
      <div className="main-container fade-in">
        <div className="text-container">
          <div className="header-text">The first visual shopping platform</div>
          <div className="supporting-text">A personalized shopping app makes it easy to turn any inspiration (image, text, color) into a fun and effortless shopping experience</div> 
          {isMobileDevice() ? (
            <div className="app-link">
              <a href="https://capsule.page.link/website" target="_blank" rel="noopener noreferrer" className="download-button">
                <img src={apple} alt="Apple Icon" className="apple" />
                Download App
              </a>
            </div>
          ) : (
            <div className="app-link">
              <a href="https://apps.apple.com/us/app/capsule-shop-your-screenshots/id1642947474" target="_blank" rel="noopener noreferrer" className="download-button">
                Download App
              </a>
            </div>
          )}
        </div>

        <div className="mockup-container">
          <img src={mockup} alt="Mockup" className="responsive-mockup" />          
        </div>


      </div>

      {/* <div className="horizontal-container">
        <div className="home-container">
          <div className="split-container">
            <div className="home-text">Shop any inspo</div>
            <img src={image1} alt="Mockup" className="home-image responsive-mockup" />
          </div>
        </div>
        <div className="home-container">
          <div className="split-container">
            <div className="home-text">Compare across retailers</div>
            <img src={image1} alt="Mockup" className="home-image responsive-mockup" />
          </div>
        </div>
        <div className="home-container">
          <div className="split-container">
            <div className="home-text">Play to earn rewards</div>
            <img src={image1} alt="Mockup" className="home-image responsive-mockup" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Home;