import React, { useState, useEffect } from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper, faDoorOpen, faCoffee, faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';
import mixpanel from "mixpanel-browser";

import softAutumnBlueDress from './images/soft_autumn/soft_autumn_blue_dress.png'
import softAutumnBlueSweater from './images/soft_autumn/soft_autumn_blue_sweater.png'
import softAutumnBrownShorts from './images/soft_autumn/soft_autumn_brown_shorts.png'
import softAutumnDenimJacket from './images/soft_autumn/soft_autumn_denim_jacket.png'
import softAutumnPinkDress from './images/soft_autumn/soft_autumn_pink_dress.png'
import softAutumnOrangeSneakers from './images/soft_autumn/soft_autumn_orange_sneakers.png'
import softAutumnPinkOnePiece from './images/soft_autumn/soft_autumn_pink_one_piece.png'
import softAutumnPurplePurse from './images/soft_autumn/soft_autumn_purple_purse.png'
import softAutumnPurpleTop from './images/soft_autumn/soft_autumn_purple_top.png'
import softAutumnTanBootie from './images/soft_autumn/soft_autumn_tan_bootie.png'
import softAutumnTanOnePiece from './images/soft_autumn/soft_autumn_tan_one_piece.png'
import softAutumnYellowSweater from './images/soft_autumn/soft_autumn_yellow_sweater.png'

import warmAutumnBag from './images/warm_autumn/warm_autumn_bag.png'
import warmAutumnBluePants from './images/warm_autumn/warm_autumn_blue_pants.png'
import warmAutumnBurntPants from './images/warm_autumn/warm_autumn_burnt_pants.png'
import warmAutumnDeepVPants from './images/warm_autumn/warm_autumn_deep_v_dress.png'
import warmAutumnDress from './images/warm_autumn/warm_autumn_dress.png'
import warmAutumnGreenLace from './images/warm_autumn/warm_autumn_green_lace.png'
import warmAutumnGreenTank from './images/warm_autumn/warm_autumn_green_tank.png'
import warmAutumnGreenTee from './images/warm_autumn/warm_autumn_green_tee.png'
import warmAutumnJacket from './images/warm_autumn/warm_autumn_jacket.png'
import warmAutumnPants from './images/warm_autumn/warm_autumn_pants.png'
import warmAutumnRedDress from './images/warm_autumn/warm_autumn_red_dress.png'
import warmAutumnShoes from './images/warm_autumn/warm_autumn_shoes.png'

import lightSpringBlueCartigan from './images/light_spring/light_spring_blue_cartigan.png'
import lightSpringBluePants from './images/light_spring/light_spring_blue_pants.png'
import lightSpringBlueSweatshirt from './images/light_spring/light_spring_blue_sweatshirt.png'
import lightSpringCreamSweater from './images/light_spring/light_spring_cream_sweater.png'
import lightSpringCreamTop from './images/light_spring/light_spring_cream_top.png'
import lightSpringFloralDress from './images/light_spring/light_spring_floral_dress.png'
import lightSpringGreenSneakers from './images/light_spring/light_spring_green_sneakers.png'
import lightSpringGreenTee from './images/light_spring/light_spring_green_tee.png'
import lightSpringOrangeDress from './images/light_spring/light_spring_orange_dress.png'
import lightSpringSneakers from './images/light_spring/light_spring_sneakers.png'
import lightSpringStripedSweater from './images/light_spring/light_spring_striped_sweater.png'
import lightSpringYellowPurse from './images/light_spring/light_spring_yellow_purse.png'

import brightSpringBlueCartigan from './images/bright_spring/bright_spring_blue_cartigan.png'
import brightSpringBlueDress from './images/bright_spring/bright_spring_blue_dress.png'
import brightSpringBluePants from './images/bright_spring/bright_spring_blue_pants.png'
import brightSpringBlueShoes from './images/bright_spring/bright_spring_blue_shoes.png'
import brightSpringGreenDress from './images/bright_spring/bright_spring_green_dress.png'
import brightSpringNecklace from './images/bright_spring/bright_spring_necklace.png'
import brightSpringPinkJacket from './images/bright_spring/bright_spring_pink_jacket.png'
import brightSpringPinkSet from './images/bright_spring/bright_spring_pink_set.png'
import brightSpringPinkShirt from './images/bright_spring/bright_spring_pink_shirt.png'
import brightSpringRainbowPants from './images/bright_spring/bright_spring_rainbow_pants.png'
import brightSpringRedDress from './images/bright_spring/bright_spring_red_dress.png'
import brightSpringRedShirt from './images/bright_spring/bright_spring_red_shirt.png'

import warmSpringBeyondYoga from './images/warm_spring/warm_spring_beyond_yoga.png'
import warmSpringBluePurse from './images/warm_spring/warm_spring_blue_purse.png'
import warmSpringCardigan from './images/warm_spring/warm_spring_cardigan.png'
import warmSpringCowboyHat from './images/warm_spring/warm_spring_cowboy_hat.png'
import warmSpringDress from './images/warm_spring/warm_spring_dress.png'
import warmSpringGreen from './images/warm_spring/warm_spring_green.png'
import warmSpringJumpsuit from './images/warm_spring/warm_spring_jumpsuit.png'
import warmSpringOrangeBodysuit from './images/warm_spring/warm_spring_orange_bodysuit.png'
import warmSpringOrangeSkirt from './images/warm_spring/warm_spring_orange_skirt.png'
import warmSpringPeachMini from './images/warm_spring/warm_spring_peach_mini.png'
import warmSpringShopperBag from './images/warm_spring/warm_spring_shopper_bag.png'
import warmSpringSweatpants from './images/warm_spring/warm_spring_sweatpants.png'
import warmSpringWhiteBoots from './images/warm_spring/warm_spring_white_boots.png'

import softSummerAdidasGreen from './images/soft_summer/soft_summer_adidas_green.png'
import softSummerBoots from './images/soft_summer/soft_summer_boots.png'
import softSummerDenimSkirt from './images/soft_summer/soft_summer_denim_skirt.png'
import softSummerGreenDress from './images/soft_summer/soft_summer_green_dress.png'
import softSummerPinkDress from './images/soft_summer/soft_summer_pink_dress.png'
import softSummerPuffer from './images/soft_summer/soft_summer_puffer.png'
import softSummerPurse from './images/soft_summer/soft_summer_purse.png'
import softSummerSportyAndRich from './images/soft_summer/soft_summer_sporty_and_rich.png'
import softSummerSunglasses from './images/soft_summer/soft_summer_sunglasses.png'
import softSummerTruckerHat from './images/soft_summer/soft_summer_trucker_hat.png'
import softSummerYellowDress from './images/soft_summer/soft_summer_yellow_dress.png'
import softSummerYellowTank from './images/soft_summer/soft_summer_yellow_tank.png'

import lightSummerBeachRiot from './images/light_summer/light_summer_beach_riot.png'
import lightSummerBlueTank from './images/light_summer/light_summer_blue_tank.png'
import lightSummerCroppedHoodie from './images/light_summer/light_summer_cropped_hoodie.png'
import lightSummerDoraLarsen from './images/light_summer/light_summer_dora_larsen.png'
import lightSummerHat from './images/light_summer/light_summer_hat.png'
import lightSummerJenRosi from './images/light_summer/light_summer_jen_rosi.png'
import lightSummerLespecs from './images/light_summer/light_summer_lespecs.png'
import lightSummerNanaJacqueline from './images/light_summer/light_summer_nana_jacqueline.png'
import lightSummerPinkPants from './images/light_summer/light_summer_pink_pants.png'
import lightSummerPurpleDress from './images/light_summer/light_summer_purple_dress.png'
import lightSummerPurpleTee from './images/light_summer/light_summer_purple_tee.png'
import lightSummerStripedShorts from './images/light_summer/light_summer_striped_shorts.png'

import coolSummerBlueDress from './images/cool_summer/cool_summer_blue_dress.png'
import coolSummerJumpSuit from './images/cool_summer/cool_summer_jump_suit.png'
import coolSummerLuluTop from './images/cool_summer/cool_summer_lulu_top.png'
import coolSummerMaroonDress from './images/cool_summer/cool_summer_maroon_dress.png'
import coolSummerPants from './images/cool_summer/cool_summer_pants.png'
import coolSummerPurpleTop from './images/cool_summer/cool_summer_purple_top.png'
import coolSummerRedJacket from './images/cool_summer/cool_summer_red_jacket.png'
import coolSummerRedShoes from './images/cool_summer/cool_summer_red_shoes.png'
import coolSummerShoes from './images/cool_summer/cool_summer_shoes.png'
import coolSummerSunglasses from './images/cool_summer/cool_summer_sunglasses.png'
import coolSummerSweater from './images/cool_summer/cool_summer_sweater.png'
import coolSummerZaraPants from './images/cool_summer/cool_summer_zara_pants.png'

import deepAutumnBeanie from './images/deep_autumn/deep_autumn_beanie.png'
import deepAutumnBlueTank from './images/deep_autumn/deep_autumn_blue_tank.png'
import deepAutumnBoots from './images/deep_autumn/deep_autumn_boots.png'
import deepAutumnColorfulTop from './images/deep_autumn/deep_autumn_colorful_top.png'
import deepAutumnDress from './images/deep_autumn/deep_autumn_dress.png'
import deepAutumnGreenDress from './images/deep_autumn/deep_autumn_green_dress.png'
import deepAutumnOrangeShorts from './images/deep_autumn/deep_autumn_orange_shorts.png'
import deepAutumnPurse from './images/deep_autumn/deep_autumn_purse.png'
import deepAutumnRedJacket from './images/deep_autumn/deep_autumn_red_jacket.png'
import deepAutumnShoes from './images/deep_autumn/deep_autumn_shoes.png'
import deepAutumnSkirt from './images/deep_autumn/deep_autumn_skirt.png'
import deepAutumnYellowSweater from './images/deep_autumn/deep_autumn_yellow_sweater.png'

import deepWinterAdidas from './images/deep_winter/deep_winter_adidas.png'
import deepWinterBlueAbercrombie from './images/deep_winter/deep_winter_blue_abercrombie.png'
import deepWinterGreenPurse from './images/deep_winter/deep_winter_green_purse.png'
import deepWinterHoodie from './images/deep_winter/deep_winter_hoodie.png'
import deepWinterMagentaSkirt from './images/deep_winter/deep_winter_magenta_skirt.png'
import deepWinterPinkDress from './images/deep_winter/deep_winter_pink_dress.png'
import deepWinterPinkPants from './images/deep_winter/deep_winter_pink_pants.png'
import deepWinterPurpleDress from './images/deep_winter/deep_winter_purple_dress.png'
import deepWinterSunglasses from './images/deep_winter/deep_winter_sunglasses.png'
import deepWinterVelvetDress from './images/deep_winter/deep_winter_velvet_dress.png'
import deepWinterYellowTop from './images/deep_winter/deep_winter_yellow_top.png'
import deepWinterYellowTshirt from './images/deep_winter/deep_winter_yellow_tshirt.png'

import clearWinterAdidas from './images/clear_winter/clear_winter_adidas.png'
import clearWinterBlueDress from './images/clear_winter/clear_winter_blue_dress.png'
import clearWinterBlueSkirt from './images/clear_winter/clear_winter_blue_skirt.png'
import clearWinterBodysuit from './images/clear_winter/clear_winter_bodysuit.png'
import clearWinterGreenSweater from './images/clear_winter/clear_winter_green_sweater.png'
import clearWinterPinkLeather from './images/clear_winter/clear_winter_pink_leather.png'
import clearWinterPinkLongsleeve from './images/clear_winter/clear_winter_pink_longsleeve.png'
import clearWinterPinkScarf from './images/clear_winter/clear_winter_pink_scarf.png'
import clearWinterPinkTop from './images/clear_winter/clear_winter_pink_top.png'
import clearWinterSunglasses from './images/clear_winter/clear_winter_sunglasses.png'
import clearWinterTee from './images/clear_winter/clear_winter_tee.png'
import clearWinterYellowTank from './images/clear_winter/clear_winter_yellow_tank.png'

import coolWinterBeanie from './images/cool_winter/cool_winter_beanie.png'
import coolWinterBlueBoots from './images/cool_winter/cool_winter_blue_boots.png'
import coolWinterBlueDress from './images/cool_winter/cool_winter_blue_dress.png'
import coolWinterDress from './images/cool_winter/cool_winter_dress.png'
import coolWinterGreenTop from './images/cool_winter/cool_winter_green_top.png'
import coolWinterHalter from './images/cool_winter/cool_winter_halter.png'
import coolWinterJumpsuit from './images/cool_winter/cool_winter_jumpsuit.png'
import coolWinterPurpleBoots from './images/cool_winter/cool_winter_purple_boots.png'
import coolWinterPurpleSweater from './images/cool_winter/cool_winter_purple_sweater.png'
import coolWinterPurpleTank from './images/cool_winter/cool_winter_purple_tank.png'
import coolWinterSweater from './images/cool_winter/cool_winter_sweater.png'
import coolWinterTank from './images/cool_winter/cool_winter_tank.png'

import './Search.css';

function Search() {
  const urlParams = new URLSearchParams(window.location.search);
  const [colorSeason, setColorSeason] = useState('')
  const [hexcode, setHexcode] = useState('');
  const [currentHexcode, setCurrentHexcode] = useState('');
  const [presetHexcodes, setPresetHexcodes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [results, setResults] = useState([]);
  const [backgroundGradient, setBackgroundGradient] = useState('');
  const [selectedColorSeasonName, setSelectedColorSeasonName] = useState('');
  const [selectedHexcode, setSelectedHexcode] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [sortedResults, setSortedResults] = useState([]);
  const [imageLoadingStatus, setImageLoadingStatus] = useState({});
  const [isDefaultResults, setIsDefaultResults] = useState(false); // New state variable

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [showColorSeasonDropdown, setShowColorSeasonDropdown] = useState(false);

  const handleColorSelect = (hexcode, seasonName) => {
    setColorSeason(seasonName);
    setSelectedColorSeasonName(seasonName);
    setShowColorSeasonDropdown(false);

    const selectedSeason = colorSeasons.find(season => season.name === seasonName);
    setPresetHexcodes(selectedSeason.hexcodes);
    setHexcode(selectedSeason.hexcodes[0])
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  useEffect(() => {
    const sorted = [...results].sort((a, b) => {
      if (sortOrder === 'priceLowToHigh') {
        return parseFloat(a.price.replace('$', '')) - parseFloat(b.price.replace('$', ''));
      } else if (sortOrder === 'priceHighToLow') {
        return parseFloat(b.price.replace('$', '')) - parseFloat(a.price.replace('$', ''));
      }
      return 0;
    });
    setSortedResults(sorted);
  }, [results, sortOrder]);

  useEffect(() => {
    setBackgroundGradient(generateRandomGradient());
  }, []);

  const generateRandomGradient = () => {
    return `linear-gradient(${Math.random() * 360}deg, ${presetHexcodes.join(', ')})`;
  };

  const onDragEnd = () => {
    setHexcode(currentHexcode)
  }

  const resultItemWidth = isDefaultResults ? 'calc(25% - 20px)' : 'calc(20% - 20px)';
  document.querySelectorAll('.result-item').forEach(item => {
    item.style.width = resultItemWidth;
  });

  const colorSeasons = [
    { name: 'Light Spring', hexcodes: ["#ee6879", "#f5ad93", "#fff1a5", "#61cb65", "#c7f1b7", "#96d6d2", "#9fe7f5", "#96c8fc"] },
    { name: 'Bright Spring', hexcodes: ["#ed4847", "#f3a6a4", "#f5bedb", "#f7b640", "#fee83c", "#65d34e", "#5fd5c5", "#304098"] },
    { name: 'Warm Spring', hexcodes: ["#489a46", "#fd836a", "#bb4845", "#f7b640", "#ee6e47", "#e27f4d", "#84d46b", "#52b8ac"] },
    { name: 'Light Summer', hexcodes: ["#a36872", "#dbcef7", "#b5a1ce", "#f9dcf4", "#89e2b4", "#90b7fb", "#b5f4fb", "#4976bf"] },
    { name: 'Cool Summer', hexcodes: ["#7c4171", "#bd8ec4", "#973055", "#d13e4d", "#183779", "#91c4ea", "#65e4dd", "#4ba685"] },
    { name: 'Soft Summer', hexcodes: ["#a06b94", "#c5787f", "#e5699b", "#828fed", "#fff6b7", "#4fad83", "#6f94b6", "#9cccc7"] },
    { name: 'Soft Autumn', hexcodes: ["#a7552e", "#e8713a", "#ecd761", "#ddc1b6", "#df867f", "#326493", "#6cbebe", "#9579a7"] },
    { name: 'Warm Autumn', hexcodes: ["#9c2d35", "#c83a25", "#ee6030", "#e8c12e", "#844221", "#194234", "#636d0d", "#80b45d"] },
    { name: 'Deep Autumn', hexcodes: ["#be3b38", "#f49931", "#e4432d", "#fcd232", "#5f2d16", "#164a74", "#1a3e01", "#3e1a3a"] },
    { name: 'Clear Winter', hexcodes: ["#622b86", "#c53c5a", "#ec5095", "#e9e0fd", "#faf736", "#57b867", "#2b85c4", "#6befdc"] },
    { name: 'Cool Winter', hexcodes: ["#872b5a", "#aa9cbd", "#6f4786", "#252b5e", "#1a57be", "#e1f5fe", "#1f565e", "#3293cd"] },
    { name: 'Deep Winter', hexcodes: ["#5d2f79", "#973055", "#324261", "#cd4486", "#fff264", "#1a2682", "#c73d57", "#1a4850"] }
  ];

  const trendingForFallColors = ["#3B2921", "#E6C58B", "#1B275E", "#534839", "#B0281E", "#F4E0E7", "#666030", "#532631"];

  useEffect(() => {
    const urlColorSeason = urlParams.get('color_season') || "";
    const hexcode = urlParams.get('hexcode');
    const formattedHexcode = hexcode ? (hexcode.startsWith('#') ? hexcode : `#${hexcode}`) : '';
    const selectedColorSeason = colorSeasons.find(colorSeason => 
      colorSeason.name.toLowerCase().replace(/ /g, '_') === urlColorSeason.toLowerCase()
    );

    if (selectedColorSeason) {
      setColorSeason(selectedColorSeason.name);
      setPresetHexcodes(selectedColorSeason.hexcodes);
      setHexcode(formattedHexcode);
      if (!formattedHexcode) {
        const options = getOptionsForSeason(selectedColorSeason.name);
        setResults(options);
        if (!options || (Array.isArray(options) && options.length === 0)) {
            setHexcode(selectedColorSeason.hexcodes[0]);
        }
        setIsDefaultResults(true)
      }
    } else if (formattedHexcode) {
      setHexcode(formattedHexcode);
      setIsDefaultResults(false);
    } else {
      setHexcode('#008080');
      setIsDefaultResults(false);
    }
  }, []);

  const getOptionsForSeason = (seasonName) => {
    return defaultOptions[seasonName] || [];
  };

  const defaultOptions = {
    'Light Spring': [
      { title: '', price: '', retailer: '', image: lightSpringGreenTee, url: 'https://sportyandrich.com/collections/shop-all/products/wellness-club-flocked-t-shirt-verde-white?_fid=a1a91d97e&_pos=13&_ss=c&pb=0&variant=45347430203650' },
      { title: '', price: '', retailer: '', image: lightSpringOrangeDress, url: 'https://showmeyourmumu.com/products/jasmine-halter-midi-dress-cantaloupe-luxe-satin?variant=40965221154859' },
      { title: '', price: '', retailer: '', image: lightSpringBluePants, url: 'https://www.farfetch.com/shopping/women/remain-striped-wide-leg-trousers-item-23298185.aspx?storeid=14288' },
      { title: '', price: '', retailer: '', image: lightSpringBlueSweatshirt, url: 'https://www.saturdaysnyc.com/products/feel-the-light-standard-long-sleeve-tee-1' },
      { title: '', price: '', retailer: '', image: lightSpringCreamSweater, url: 'https://www.jcrew.com/p/womens/categories/clothing/sweaters/cardigan/crewneck-cardigan-sweater-in-premium-ultrafine-cotton/CB893?color_name=ecru&N=SMALL&noPopUp=true&srcCode=Paid_Search%7CShopping_NonBrand%7CGoogle%7CPMG%5EG%5E99107503955_20934478975___c_pla_local_609_9031981&utm_source=Google&utm_medium=paid_search&utm_content=shopping_ads&utm_campaign=JCrew_Shopping_PLA_US_All_Sweaters_PMax_Womens_Google+_X&utm_term=&gad_source=1&gclid=Cj0KCQjwgrO4BhC2ARIsAKQ7zUlnotrNXmbz6Wb3o6kNSGw31wvPYfQcIDcT51Vf7wy9l5_BCBHJUrAaAidAEALw_wcB&gclsrc=aw.ds#no_universal_links#no_universal_links' },
      { title: '', price: '', retailer: '', image: lightSpringCreamTop, url: 'https://petalandpup.com/products/alice-corset-top-honeysuckle-yellow?variant=43970735145137' },
      { title: '', price: '', retailer: '', image: lightSpringFloralDress, url: 'https://lime-shop.com/ww_en/product/19289_8520_369-mnogocvetnyi' },
      { title: '', price: '', retailer: '', image: lightSpringGreenSneakers, url: 'https://www.onitsukatiger.com/jp/en-gl/product/mexico-66-cactful-s/1183c347_300.html' },
      { title: '', price: '', retailer: '', image: lightSpringSneakers, url: 'https://www.farfetch.com/shopping/women/new-balance-9060-chrome-blue-sneakers-item-21774063.aspx?storeid=14912' },
      { title: '', price: '', retailer: '', image: lightSpringStripedSweater, url: 'https://www.ramybrook.com/products/madge-tie-front-cardigan?variant=40097790263360' },
      { title: '', price: '', retailer: '', image: lightSpringYellowPurse, url: 'https://shop.mango.com/us/en/p/women/bags/crossbody/crossbody-bag-with-flap_77002012?c=11' },
      { title: '', price: '', retailer: '', image: lightSpringBlueCartigan, url: 'https://kitristudio.com/products/pandora-blue-check-boucle-knit-cardigan' },
    ],
    'Bright Spring': [
      { title: '', price: '', retailer: '', image: brightSpringBlueDress, url: 'https://www.revolve.com/amanda-uprichard-x-revolve-anja-mini-dress-in-tropical-turquoise/dp/AMAN-WD1715/?d=Womens&page=1&lc=91&plpSrc=%2Fr%2FBrands.jsp%3Furl%3Dhttps%3A%2F%2Fwww.revolve.com%2Fr%2FBrandsContent.jsp%3FaliasURL%3Dclothing%252Fbr%252F3699fc%26s%3Dc%26c%3DClothing%26navsrc%3Dsubclothing%26color%3Dblue%26filters%3Dcolor%26lazyLoadedPlp%3Dtrue%26sortBy%3Dfeatured%26productsPerRow%3D4%26preLoadCategory%3D%26preLoadDesigner%3D%26lazyLang%3Den%26lazyCountryCode%3DUS%26lazyCurrency%3DUSD%26_%3D1729230848950&itrownum=19&itcurrpage=1&itview=05' },
      { title: '', price: '', retailer: '', image: brightSpringPinkShirt, url: 'https://whitefoxboutique.com/products/with-love-romance-oversized-tee-baby-pink?variant=42087914700860' },
      { title: '', price: '', retailer: '', image: brightSpringBlueShoes, url: '' },
      { title: '', price: '', retailer: '', image: brightSpringRedDress, url: 'https://www.neverfullydressed.com/en-us/products/red-diaz-dress' },
      { title: '', price: '', retailer: '', image: brightSpringGreenDress, url: 'https://www.stories.com/en_usd/clothing/dresses/midi-dresses/product.collared-wrap-midi-dress-green.1059510004.html' },
      { title: '', price: '', retailer: '', image: brightSpringNecklace, url: 'https://lime-shop.com/ww_en/product/20139_9841_245-krasnyi' },
      { title: '', price: '', retailer: '', image: brightSpringPinkSet, url: 'https://www.hellomolly.com/collections/tops/products/bring-it-on-crop-top-pink' },
      { title: '', price: '', retailer: '', image: brightSpringBlueCartigan, url: 'https://kitristudio.com/products/pandora-blue-check-boucle-knit-cardigan' },
      { title: '', price: '', retailer: '', image: brightSpringPinkJacket, url: 'https://www.lulus.com/products/cozy-allover-pink-vegan-leather-shearling-trim-moto-jacket/2459111.html' },
      { title: '', price: '', retailer: '', image: brightSpringBluePants, url: 'https://www.urbanoutfitters.com/shop/out-from-under-hoxton-sweatpant?category=womens-clothing&color=043&viewcode=b&type=REGULAR&quantity=1' },
      { title: '', price: '', retailer: '', image: brightSpringRainbowPants, url: 'https://www.misalosangeles.com/products/nico-pant-cali-ombre-cotton?variant=47611177861313' },
      { title: '', price: '', retailer: '', image: brightSpringRedShirt, url: 'https://www.pacsun.com/ps-%2F-la/bow-baby-t-shirt-0702512320073.html?dwvar_0702512320073_color=060&tileCgid=womens-tops' },
    ],
    'Warm Spring': [
      { title: '', price: '', retailer: '', image: warmSpringBluePurse, url: 'https://www.walmart.com/ip/Teal-Blue-Cracked-Marble-Womens-Chain-Shoulder-Bag-Tote-Handbag-Clutch-Hobo-Purse-with-Zipper-for-Travel-Casual/8044300238?wmlspartner=wlpa&selectedSellerId=102508805' },
      { title: '', price: '', retailer: '', image: warmSpringCardigan, url: 'https://lisamariefernandez.com/products/new-marigold-crepe-cardigan' },
      { title: '', price: '', retailer: '', image: warmSpringOrangeSkirt, url: 'https://www.revolve.com/moon-skort-in-tigerlily/dp/NIAR-WF2/?d=Womens&srcType=dp_style_pdpctl&sectionURL=undefined' },
      { title: '', price: '', retailer: '', image: warmSpringCowboyHat, url: 'https://senderopc.com/products/cowboy-hat' },
      { title: '', price: '', retailer: '', image: warmSpringGreen, url: 'https://normakamali.com/products/legging-w-mesh-bottom-neon-green-neon-green-mesh-kk1237pm997978' },
      { title: '', price: '', retailer: '', image: warmSpringDress, url: 'https://macduggal.com/products/49531' },
      { title: '', price: '', retailer: '', image: warmSpringShopperBag, url: 'https://www.oliverbonas.com/us/accessories/niki-net-lime-sparkle-fabric-shopper-bag-350420?utm_source=google&utm_medium=free&utm_campaign=free_listings_us&utm_term=1641172&noredir=1&srsltid=AfmBOoqorgb8cGVX8ume030Wv2vthrIxWhKjVDpW5AHDq_uslAE0qGf82Oo' },
      { title: '', price: '', retailer: '', image: warmSpringJumpsuit, url: 'https://www.lulus.com/products/energetic-appeal-rust-orange-corduroy-sleeveless-belted-jumpsuit/2478831.html?pla=1&utm_source=google&utm_medium=cpc&utm_campaign=%5BADL%5D%20%5BPLA%5D%20%5BGoogle%5D%20%5BUS%5D%20Jumpsuits%20-%20tROAS%20-%20Boost&utm_term=JPS1394%3A%20RUST2&utm_content=176534060628_715010414848&s_kwcid=AL%217824%213%21715010414848%21%21%21g%212430958382837%21&gad_source=1&gclid=CjwKCAjwjsi4BhB5EiwAFAL0YAPFIJ6tdmX93zk6cCfHjlYVVXXnwVwTnvupfVyAebrNpr_-E3h3qxoCS6gQAvD_BwE' },
      { title: '', price: '', retailer: '', image: warmSpringOrangeBodysuit, url: 'https://dodobaror.com/products/helen-bodysuit-orange' },
      { title: '', price: '', retailer: '', image: warmSpringWhiteBoots, url: 'https://dingo1969.com/products/out-west-leather-boot-white' },
      { title: '', price: '', retailer: '', image: warmSpringBeyondYoga, url: 'https://beyondyoga.com/products/spacedye-well-rounded-cropped-halter-tank-sunflower-heather-sd4655' },
      { title: '', price: '', retailer: '', image: warmSpringSweatpants, url: 'https://rollerrabbit.com/products/hearts-puff-sweatpants' },
    ],
    'Light Summer': [
      { title: '', price: '', retailer: '', image: lightSummerBeachRiot, url: 'https://beachriot.com/products/alessia-legging-jade-sky' },
      { title: '', price: '', retailer: '', image: lightSummerPurpleTee, url: 'https://bailey44.com/products/sundry-bon-voyage-muscle-tank-in-ocean' },
      { title: '', price: '', retailer: '', image: lightSummerHat, url: 'https://www.aloyoga.com/products/a0375u-faux-fur-bucket-hat-sugarplum-pink' },
      { title: '', price: '', retailer: '', image: lightSummerBlueTank, url: 'https://susanamonaco.com/products/wide-strap-crop-top?variant=44975051342044' },
      { title: '', price: '', retailer: '', image: lightSummerCroppedHoodie, url: 'https://bydyln.com/products/anderson-crop-hoodie-grape' },
      { title: '', price: '', retailer: '', image: lightSummerDoraLarsen, url: 'https://doralarsen.com/products/aerin-body' },
      { title: '', price: '', retailer: '', image: lightSummerJenRosi, url: 'https://jenrossicollection.com/products/ramblin-jacket?variant=44045178732797' },
      { title: '', price: '', retailer: '', image: lightSummerLespecs, url: 'https://lespecs.com/products/51-angel-icy-blue-lic2431607' },
      { title: '', price: '', retailer: '', image: lightSummerNanaJacqueline, url: 'https://nanajacqueline.com/products/chelsea-knit-top-pink' },
      { title: '', price: '', retailer: '', image: lightSummerPinkPants, url: 'https://area.nyc/products/crystal-slit-jean-powder-pink?variant=46593339392232' },
      { title: '', price: '', retailer: '', image: lightSummerPurpleDress, url: 'https://www.aritzia.com/us/en/product/bloomsbury-dress/117057.html?dwvar_117057_color=23866&country=us' },
      { title: '', price: '', retailer: '', image: lightSummerStripedShorts, url: 'https://us.princesspolly.com/products/beach-house-shorts-green-stripe' },
    ],
    'Soft Summer': [
      { title: '', price: '', retailer: '', image: softSummerSportyAndRich, url: 'https://sportyandrich.com/products/new-health-wellness-crewneck-verde-white?utm_source=google&campaign_id=18308941182&ad_id=&utm_medium=cpc&utm_campaign=actual_campaign_name_hardcoded&utm_content=&utm_term=&gclid=CjwKCAjwjsi4BhB5EiwAFAL0YJD38NOBB_PcEs_5rOAhSjcjYl2RMkrP1IypU8VIv9Ne0QxycGrjrBoCNPoQAvD_BwE&gad_source=1&variant=45205562622210' },
      { title: '', price: '', retailer: '', image: softSummerBoots, url: 'https://laneboots.com/products/cossette-powder-blue' },
      { title: '', price: '', retailer: '', image: softSummerDenimSkirt, url: 'https://retrofete.com/products/wynter-skirt-oasis' },
      { title: '', price: '', retailer: '', image: softSummerYellowDress, url: 'https://www.nordstrom.com/s/rosetta-cutout-cotton-linen-strapless-dress/7753241' },
      { title: '', price: '', retailer: '', image: softSummerPinkDress, url: 'https://petalandpup.com/products/kelly-long-sleeve-sequin-mini-dress-berry?variant=42247557841073' },
      { title: '', price: '', retailer: '', image: softSummerPuffer, url: 'https://frankiecollective.com/products/vintage-sport-puffer-jacket' },
      { title: '', price: '', retailer: '', image: softSummerPurse, url: 'https://www.senreve.com/products/aria-belt-bag-mimosa?variant=40250306035800' },
      { title: '', price: '', retailer: '', image: softSummerGreenDress, url: 'https://www.shopjaus.com/products/nookie-muse-gown-verde' },
      { title: '', price: '', retailer: '', image: softSummerSunglasses, url: 'https://lespecs.com/products/hydrus-link-pink-rose-lmi2231743' },
      { title: '', price: '', retailer: '', image: softSummerTruckerHat, url: 'https://www.gigipip.com/products/saddleback-foam-trucker-hat?variant=44356257841283' },
      { title: '', price: '', retailer: '', image: softSummerAdidasGreen, url: 'https://www.adidas.com/us/gazelle-shoes/IG1634.html' },
      { title: '', price: '', retailer: '', image: softSummerYellowTank, url: 'https://whitefoxboutique.com/products/taking-off-top-lemon?variant=40696800313404' },
    ],
    'Cool Summer': [
      { title: '', price: '', retailer: '', image: coolSummerBlueDress, url: 'https://realisationpar.com/the-gia-indigo/' },
      { title: '', price: '', retailer: '', image: coolSummerJumpSuit, url: 'https://www.abercrombie.com/shop/us/search?departmentId=12203-ANF&filtered=true&rows=42&searchTerm=pink&start=0' },
      { title: '', price: '', retailer: '', image: coolSummerLuluTop, url: 'https://shop.lululemon.com/p/women-tanks/Ultralu-Cut-Out-Racerback-Tank-Top-MD/_/prod11783966' },
      { title: '', price: '', retailer: '', image: coolSummerShoes, url: 'https://www.onitsukatiger.com/jp/en-gl/product/colorado-eighty-five-nm/1183b445_100.html' },
      { title: '', price: '', retailer: '', image: coolSummerPants, url: 'https://www.bstn.com/us_en/p/adidas-adibreak-pant-ip0616-0310973?utm_source=google&utm_medium=cpc&utm_campaign=%5BUS%5D%20(Shopping)%20(google)%20(Free)%20en_All%20Products&srsltid=AfmBOoqx510r4kKNxgGGx4fFujmfSJoCAkFLIlYZpMqpNUVGETMaMAy1mAo' },
      { title: '', price: '', retailer: '', image: coolSummerMaroonDress, url: 'https://www.revolve.com/amanda-uprichard-franny-mini-dress-in-aubergine/dp/AMAN-WD1622/?d=Womens&page=1&lc=4&plpSrc=%2Fr%2FBrands.jsp%3FaliasURL%3Dclothing%2Fbr%2F3699fc%26s%3Dc%26c%3DClothing%26color%3Dred%26filters%3Dcolor%26lazyLoadedPlp%3Dtrue%26sortBy%3Dfeatured&itrownum=1&itcurrpage=1&itview=05' },
      { title: '', price: '', retailer: '', image: coolSummerPurpleTop, url: 'https://us.princesspolly.com/products/angelical-top-purple' },
      { title: '', price: '', retailer: '', image: coolSummerRedJacket, url: 'https://lime-shop.com/ww_en/product/20039_8036_146-krasnyi' },
      { title: '', price: '', retailer: '', image: coolSummerRedShoes, url: 'https://shop.mango.com/us/en/p/women/shoes/heeled-shoes/bow-heeled-shoes_67034469?c=70' },
      { title: '', price: '', retailer: '', image: coolSummerSweater, url: 'https://www.stories.com/en_usd/clothing/knitwear/cardigans/product.mohair-blend-rib-knit-cardigan-blue.1239937001.html' },
      { title: '', price: '', retailer: '', image: coolSummerZaraPants, url: 'https://www.zara.com/us/en/straight-leg-corduroy-pants-p04391518.html?v1=382668848&v2=2420795' },
      { title: '', price: '', retailer: '', image: coolSummerSunglasses, url: 'https://www.revolve.com/gucci-gg-corner-cat-eye-sunglasses-in-shiny-solid-light-blue/dp/GUCR-WG18/?d=Womens&page=1&lc=81&itrownum=41&itcurrpage=1&itview=05' },

    ],
    'Soft Autumn': [
      { title: '', price: '', retailer: '', image: softAutumnBlueDress, url: 'https://us.princesspolly.com/products/lars-maxi-dress-turquoise' },
      { title: '', price: '', retailer: '', image: softAutumnYellowSweater, url: 'https://www.zara.com/us/en/100-wool-basic-short-sleeve-top-p02893104.html?v1=364117360&v2=2419940' },
      { title: '', price: '', retailer: '', image: softAutumnPurplePurse, url: 'https://us.poppylissiman.com/products/pippen-purple' },
      { title: '', price: '', retailer: '', image: softAutumnBlueSweater, url: 'https://www.farfetch.com/shopping/women/allude-long-sleeve-sweater-item-24890737.aspx?storeid=11828' },
      { title: '', price: '', retailer: '', image: softAutumnBrownShorts, url: 'https://www.revolve.com/cultnaked-faux-leather-short-in-choco/dp/CULR-WF1/?d=Womens' },
      { title: '', price: '', retailer: '', image: softAutumnDenimJacket, url: 'https://www.sprwmn.com/products/trench-coat-coco' },
      { title: '', price: '', retailer: '', image: softAutumnPinkDress, url: 'https://us.princesspolly.com/products/irena-strapless-mini-dress-dusty-pink' },
      { title: '', price: '', retailer: '', image: softAutumnOrangeSneakers, url: 'https://www.stevemadden.com/collections/womens-sneakers/products/verdict-orange-multi' },
      { title: '', price: '', retailer: '', image: softAutumnPinkOnePiece, url: 'https://www.lulus.com/products/splendidly-stunning-dusty-rose-velvet-lace-up-wide-leg-jumpsuit/2513011.html' },
      { title: '', price: '', retailer: '', image: softAutumnPurpleTop, url: 'https://afends.com/products/afends-womens-lula-hemp-knit-long-sleeve-cropped-top-plum' },
      { title: '', price: '', retailer: '', image: softAutumnTanBootie, url: 'https://staud.clothing/collections/boots/products/western-wally-ankle-boot-tan' },
      { title: '', price: '', retailer: '', image: softAutumnTanOnePiece, url: 'https://www.aritzia.com/us/en/product/tnabutter%E2%84%A2-acrobat-romper/107819.html' },
    ],
    'Warm Autumn': [
      { title: '', price: '', retailer: '', image: warmAutumnGreenTank, url: 'https://www.shopcider.com/product/detail?pid=100203224&style_id=1172600&sku_id=1342419&currency=USD&local=en&country=US&utm_source=google_shopping&srsltid=AfmBOorYvAZC789cF3ZhdTdVVtV_qbkZDjw1n1Gza4sndV-LK2kGH9DCaIc' },
      { title: '', price: '', retailer: '', image: warmAutumnGreenLace, url: 'https://www.neverfullydressed.com/en-us/products/olive-gianna-dress' },
      { title: '', price: '', retailer: '', image: warmAutumnBurntPants, url: 'https://splendid.com/products/kate-young-x-splendid-cupro-pant' },
      { title: '', price: '', retailer: '', image: warmAutumnDeepVPants, url: 'https://nookie.com.au/products/minx-gown-cherry' },
      { title: '', price: '', retailer: '', image: warmAutumnJacket, url: 'https://www.aviatornation.com/products/womens-color-block-half-and-half-apres-puffer-jacket-honey-glossy' },
      { title: '', price: '', retailer: '', image: warmAutumnBag, url: 'https://veronicabeard.com/products/large-crest-lock-bucket-bag-hazelwood?variant=43336075051179' },
      { title: '', price: '', retailer: '', image: warmAutumnDress, url: 'https://www.aloyoga.com/products/w8230r-euphoria-long-sleeve-dress-midnight-green' },
      { title: '', price: '', retailer: '', image: warmAutumnGreenTee, url: 'https://www.aritzia.com/us/en/product/everyday-hip-t-shirt/95781.html' },
      { title: '', price: '', retailer: '', image: warmAutumnBluePants, url: 'https://www.farfetch.com/shopping/women/johanna-parv-cover-trousers-item-25606945.aspx?storeid=11585' },
      { title: '', price: '', retailer: '', image: warmAutumnPants, url: 'https://sea-and-wander.com/products/the-ditcher-roller-zip-sneak-pants-dark-green' },
      { title: '', price: '', retailer: '', image: warmAutumnRedDress, url: 'https://thecommense.com/products/mockneck-oblique-cutout-rib-long-sleeve-short-dress?variant=44212679344385' },
      { title: '', price: '', retailer: '', image: warmAutumnShoes, url: 'https://www.onitsukatiger.com/jp/en-gl/product/colorado-eighty-five-nm/1183b703_752.html' },
    ],
    'Deep Autumn': [
      { title: '', price: '', retailer: '', image: deepAutumnBlueTank, url: 'https://whitefoxboutique.com/products/new-expression-tank-ocean?variant=40888429609020' },
      { title: '', price: '', retailer: '', image: deepAutumnGreenDress, url: 'https://www.shopcider.com/goods/high-neck-sleeveless-pleated-maxi-dress-101075423?style_id=2048380&gaListId=0&gaListName=search_list_deep%20green&ciderListId=4&ciderListName=search_list_deep%20green' },
      { title: '', price: '', retailer: '', image: deepAutumnColorfulTop, url: 'https://www.anthropologie.com/shop/farm-rio-cross-front-pullover-sweater?color=060&recommendation=pdp-primary-sfrectray-pdptopcobrowsecollabfilteringcategorycolorboost&type=STANDARD&quantity=1' },
      { title: '', price: '', retailer: '', image: deepAutumnSkirt, url: 'https://www.astrthelabel.com/products/faux-suede-mini-skirt?variant=41747199951024' },
      { title: '', price: '', retailer: '', image: deepAutumnDress, url: 'https://petalandpup.com/products/anabelle-halter-neck-mini-dress-teal?variant=42337615347889' },
      { title: '', price: '', retailer: '', image: deepAutumnBoots, url: 'https://stetson.com/products/parker-brown' },
      { title: '', price: '', retailer: '', image: deepAutumnPurse, url: 'https://www.urbanoutfitters.com/shop/hvisk-nomi-bag?category=SEARCHRESULTS&color=050&searchparams=q=deep%2520purple&type=REGULAR&size=ONE+SIZE&quantity=1' },
      { title: '', price: '', retailer: '', image: deepAutumnYellowSweater, url: 'https://www.amiparis.com/en-us/products/yellow-wool-off-white-ami-de-coeur-polo-upl325kn0032724' },
      { title: '', price: '', retailer: '', image: deepAutumnShoes, url: 'https://us.puma.com/us/en/pd/speedcat-og-+-sparco-driving-shoes/307171' },
      { title: '', price: '', retailer: '', image: deepAutumnOrangeShorts, url: 'https://www.youngla.com/products/117-shorts-115-shorts-2-0?variant=42268802777276&currency=USD&utm_medium=product_sync&utm_source=google&utm_content=sag_organic&utm_campaign=sag_organic&nbt=nb%3Aadwords%3Ax%3A20353272852%3A%3A&nb_adtype=pla&nb_kwd=&nb_ti=&nb_mi=116664369&nb_pc=online&nb_pi=shopify_US_7676280275132_42268802777276&nb_ppi=&nb_placement=&nb_li_ms=&nb_lp_ms=&nb_fii=&nb_ap=&nb_mt=&tw_source=google&tw_adid=&tw_campaign=20353272852&gad_source=1&gclid=CjwKCAjwjsi4BhB5EiwAFAL0YGdzUrDuihZQnZrYggUOHuwaQvS_5BuTvXFzs-j4qqSeDJlhvaaCSRoCO60QAvD_BwE' },
      { title: '', price: '', retailer: '', image: deepAutumnRedJacket, url: 'https://khaite.com/products/sue-jacket-in-oxblood' },
      { title: '', price: '', retailer: '', image: deepAutumnBeanie, url: '' },
    ],
    'Clear Winter': [
      { title: '', price: '', retailer: '', image: clearWinterBodysuit, url: 'https://susanamonaco.com/products/open-back-mock-bodysuit' },
      { title: '', price: '', retailer: '', image: clearWinterPinkLeather, url: 'https://www.revolve.com/bardot-polly-faux-leather-pant-in-hot-pink/dp/BARD-WP44/?d=Womens&page=1&lc=7&plpSrc=%2Fr%2FSearch.jsp%3Fsearch%3Dpink%26d%3DWomens%26sortBy%3Dfeatured&itrownum=2&itcurrpage=1&itview=05' },
      { title: '', price: '', retailer: '', image: clearWinterAdidas, url: 'https://www.farfetch.com/shopping/women/adidas-gazelle-flash-aqua-lucid-pink-platform-sneakers-item-21345379.aspx?storeid=11218' },
      { title: '', price: '', retailer: '', image: clearWinterGreenSweater, url: 'https://www.baysebrand.com/products/jasper-skirt-lush-green' },

      { title: '', price: '', retailer: '', image: clearWinterBlueDress, url: 'https://nanajacqueline.com/collections/all-clothing-1/products/jennifer-dress-blue' },
      { title: '', price: '', retailer: '', image: clearWinterBlueSkirt, url: 'https://www.revolve.com/the-sei-ruched-mini-skirt-in-sky/dp/TSEI-WQ28/?d=F&currency=USD&countrycode=US&deeplink=true&utm_source=google&utm_medium=cpc&utm_campaign=PMax:+Product+Listing+Ads+-+PLA+-+Smart+Shopping+-+High+CvR&gad_source=1&gclid=CjwKCAjwjsi4BhB5EiwAFAL0YNUd771DYOgGEv3k-DHZIg7tHXbJrAVYU3S0SxmnlXHVwNGZT41MqBoC_vEQAvD_BwE&gclsrc=aw.ds' },
      { title: '', price: '', retailer: '', image: clearWinterTee, url: 'https://www.zara.com/us/en/text-slub-yarn-t-shirt-p05643806.html?v1=380836867' },

      { title: '', price: '', retailer: '', image: clearWinterPinkLongsleeve, url: 'https://www.farfetch.com/shopping/women/marine-serre-moon-jersey-t-shirt-item-24386898.aspx?storeid=13537' },
      { title: '', price: '', retailer: '', image: clearWinterPinkScarf, url: 'https://shop.mango.com/us/en/p/women/scarves-and-foulards/scarves/fringed-edge-scarf_77044758?c=68' },

      { title: '', price: '', retailer: '', image: clearWinterPinkTop, url: 'https://www.aritzia.com/us/en/product/hall-sweater-vest/119496.html?dwvar_119496_color=32007' },
      { title: '', price: '', retailer: '', image: clearWinterSunglasses, url: 'https://us.poppylissiman.com/products/heyuboy-green?_pos=4&_sid=0a2662411&_ss=r' },
      { title: '', price: '', retailer: '', image: clearWinterYellowTank, url: 'https://us.motelrocks.com/products/peggy-top-mini-floral-terry-yellow' },
    ],
    'Cool Winter': [
      { title: '', price: '', retailer: '', image: coolWinterBeanie, url: 'https://shop.lululemon.com/p/hats/Close-Wool-Blend-Ribbed-Knit-Beanie/_/prod11130342?color=63407&sz=ONESIZE' },
      { title: '', price: '', retailer: '', image: coolWinterHalter, url: 'https://nanajacqueline.com/products/jennifer-top-purple?epik=dj0yJnU9QldYamd0eUlYWEpNdjZiREdiZXFvTlRCWmF2LVBBTEwmcD0wJm49Y1pVdndsUkRqX3htdGJFdFRDQjBnQSZ0PUFBQUFBR2NUUV9V' },
      { title: '', price: '', retailer: '', image: coolWinterPurpleBoots, url: 'https://www.us.maguireshoes.com/collections/women/products/salema-violet-boot' },
      { title: '', price: '', retailer: '', image: coolWinterBlueDress, url: 'https://nookie.com.au/products/aria-one-shoulder-gown-blue' },
      { title: '', price: '', retailer: '', image: coolWinterDress, url: 'https://www.farfetch.com/shopping/women/nanushka-draped-detail-maxi-dress-item-24759535.aspx' },
      { title: '', price: '', retailer: '', image: coolWinterGreenTop, url: 'https://patbo.com/products/fringe-trim-maxi-skirt-galapagos' },
      { title: '', price: '', retailer: '', image: coolWinterPurpleTank, url: 'https://us.motelrocks.com/products/derse-top-watercolour-floral-berry?bis_id=dWMvxgO&utm_campaign=stock-notification&utm_content=Derse+One+Shoulder+Mesh+Top+in+Watercolour+Floral+Berry&utm_medium=email&utm_source=back-in-stock&variant=43191068459207' },
      { title: '', price: '', retailer: '', image: coolWinterBlueBoots, url: 'https://toral-shoes.com/products/aura-dark-mare-mock-croc-tall-boots?variant=40854140682380' },
      { title: '', price: '', retailer: '', image: coolWinterPurpleSweater, url: 'https://www.freepeople.com/shop/cable-knit-sweatshirt' },
      { title: '', price: '', retailer: '', image: coolWinterJumpsuit, url: 'https://www.odabash.com/products/naomi-navy-jumpsuit' },
      { title: '', price: '', retailer: '', image: coolWinterTank, url: 'https://shopmicas.com/products/mcwtt2302200066?currency=USD&variant=43778864546008&utm_source=google&utm_medium=cpc&utm_campaign=Google%20Shopping&stkn=4aea074dcaf9&gad_source=4&gclid=CjwKCAjwjsi4BhB5EiwAFAL0YBkoUWALFQJal0T1YsX8PiCai8gYpweiCMFKCS5Wr0KtRkIbkndVcRoCvf4QAvD_BwE' },
      { title: '', price: '', retailer: '', image: coolWinterSweater, url: 'https://www.zara.com/us/en/high-collar-knit-cardigan-p04938101.html?v1=375199382' },

    ],
    'Deep Winter': [
      { title: '', price: '', retailer: '', image: deepWinterYellowTop, url: 'https://tigermist.com/products/corvin-top-yellow-lemon' },
      { title: '', price: '', retailer: '', image: deepWinterGreenPurse, url: 'https://www.jwpei.com/products/eva-shoulder-bag-dark-green-croc?srsltid=AfmBOopSANSB6_KpbDLMvUWFMvvQSVafkcrNxXkiuIBxHhzuFh2aKJDf' },
      { title: '', price: '', retailer: '', image: deepWinterPinkPants, url: 'https://www.theoutnet.com/en-us/shop/product/victoria-beckham/pants/flared-pants/cotton-blend-velvet-flared-pants/1647597335869318?utm_source=partnerize&utm_medium=affiliation&utm_campaign=xq_shopstyle&clickref=1100lzIIUczA' },
      { title: '', price: '', retailer: '', image: deepWinterBlueAbercrombie, url: 'https://www.abercrombie.com/shop/us/p/long-sleeve-off-the-shoulder-draped-top-58445819?categoryId=12203&faceout=model&seq=07&afsource=social+proofing' },
      { title: '', price: '', retailer: '', image: deepWinterAdidas, url: 'https://www.adidas.com/us/campus-00s-shoes/H03471.html?epik=dj0yJnU9TnQ1eEVZWC1JcDdpQVdxODBWTTlZYlFRdzZjeDZiTFMmcD0wJm49cTFxaWdUaWdBT1dYS0xxVW5YNlVRdyZ0PUFBQUFBR2NTdzNN&forceSelSize=8' },
      { title: '', price: '', retailer: '', image: deepWinterHoodie, url: 'https://www.aloyoga.com/products/u3032rg-accolade-hoodie-navy?variant=4233730257733' },
      { title: '', price: '', retailer: '', image: deepWinterMagentaSkirt, url: 'https://ca.boohoo.com/petite-satin-bias-midaxi-slip-skirt-/GZZ37099.html' },
      { title: '', price: '', retailer: '', image: deepWinterYellowTshirt, url: 'https://www.farfetch.com/shopping/women/polo-ralph-lauren-ribbed-short-sleeve-t-shirt-item-19215230.aspx?fsb=1&epik=dj0yJnU9emt0OVRzZC1oeHhOVEJlcmlYUnJMQlZ2bFF5cThydWkmcD0wJm49SERwdTd0dTdvUVNkRGtKdk1qMEZIUSZ0PUFBQUFBR2NTdnZJ' },
      { title: '', price: '', retailer: '', image: deepWinterPurpleDress, url: 'https://www.shopcider.com/goods/satin-high-neck-backless-short-dress?style_id=134394&gaListId=everything&gaListName=Everything&ciderListId=2&ciderListName=Everything&selectSkuIndex=0&moduleTitle=1&p=1030944&shopPage=6&shopIndex=42&navTitle=Everything&navId=everything&listSource=homepage%3Bcollection_everything%3B2&listSort=&listId=12&listAttribute=Color%3DPurple&operationContent=Clothing&operationImage=&linkUrl=https%3A%2F%2Fm.shopcider.com%2Fcollection%2Feverything&operationpageTitle=homepage&operationPosition=2&operationType=category&productPosition=43&strategyNameList=reco_longterm_exp%3Abase-collectionId%3A12%3AControl&businessTracking=eyJmaXJzdEltYWdlVHlwZSI6Iuato%20mdouWbviIsIml0ZW1UeXBlIjowLCJsaXN0SWQiOjEyLCJsaXN0VGl0bGUiOiJFdmVyeXRoaW5nIiwibGlzdFR5cGUiOjIsIm1vZHVsZUlkIjozLCJtb2R1bGVUeXBlIjoiQ09MTEVDVElPTl9JTlBVVCIsInByb2R1Y3RQcmltYXJ5SW1hZ2VGbGFnIjp0cnVlLCJzYWxlUHJpY2UiOjIyLjAwLCJzY2VuZSI6IkNPTExFQ1RJT05fMTIiLCJza2NGaXJzdEltZyI6IjE2NzM0NDAzMTUwMDAtcERRVG53LmpwZyIsInNwdSI6MTAzMDk0NCwic3B1Q29kZSI6IkNEMjAyMjExMjIyMTA5OTBNRCIsInN0cmF0ZWd5TmFtZSI6InJlY29fbG9uZ3Rlcm1fZXhwOmJhc2UtY29sbGVjdGlvbklkOjEyOkNvbnRyb2wiLCJ0cmFja2luZ0lkIjoiZDc3OWUzZGUtMjA4NS00NGQwLWFlZTAtNDgxMmNlYTY2MDg4In0%3D&listType=2&listTitle=Everything%5C' },
      { title: '', price: '', retailer: '', image: deepWinterPinkDress, url: 'https://bananarepublicfactory.gapfactory.com/browse/product.do?pid=535007001&vid=1&tid=bfpl000032&kwid=1&ap=7&gad_source=1&gclid=CjwKCAjwjsi4BhB5EiwAFAL0YLc_QJexm_ZbhERilWTMsbA3zk1UFWG09mgaInnnuL6nW8E7eLksMhoCqrIQAvD_BwE&gclsrc=aw.ds#pdp-page-content' },
      { title: '', price: '', retailer: '', image: deepWinterSunglasses, url: 'https://us.poppylissiman.com/products/ketyl-maroon' },
      { title: '', price: '', retailer: '', image: deepWinterVelvetDress, url: 'https://www.shopcider.com/goods/velvet-v-neck-floral-cut-out-knotted-midi-dress-113849522?style_id=2049475&gaListId=everything&gaListName=Everything&ciderListId=2&ciderListName=Everything&selectSkuIndex=0&moduleTitle=1&p=113849522&shopPage=4&shopIndex=14&navTitle=Everything&navId=everything&listSource=homepage%3Bcollection_everything%3B2&listSort=&listId=12&listAttribute=Color%3DRed%2CPurple&operationContent=Clothing&operationImage=&linkUrl=https%3A%2F%2Fm.shopcider.com%2Fcollection%2Feverything&operationpageTitle=homepage&operationPosition=2&operationType=category&productPosition=15&strategyNameList=reco_longterm_exp%3Abase-collectionId%3A12%3AControl&businessTracking=eyJmaXJzdEltYWdlVHlwZSI6Iuato%20mdouWbviIsIml0ZW1UeXBlIjowLCJsaXN0SWQiOjEyLCJsaXN0VGl0bGUiOiJFdmVyeXRoaW5nIiwibGlzdFR5cGUiOjIsIm1vZHVsZUlkIjozLCJtb2R1bGVUeXBlIjoiQ09MTEVDVElPTl9JTlBVVCwgTkVXX0JPT1NUIiwicHJvZHVjdFByaW1hcnlJbWFnZUZsYWciOnRydWUsInNhbGVQcmljZSI6MzIuMDAsInNjZW5lIjoiQ09MTEVDVElPTl8xMiIsInNrY0ZpcnN0SW1nIjoiMTcyNjA0NzMzNzAwMC1EQ0VDYTQuanBnIiwic3B1IjoxMTM4NDk1MjIsInNwdUNvZGUiOiJDRDIwMjQwNzIzNTE4MTM1TUQiLCJzdHJhdGVneU5hbWUiOiJyZWNvX2xvbmd0ZXJtX2V4cDpiYXNlLWNvbGxlY3Rpb25JZDoxMjpDb250cm9sIiwidHJhY2tpbmdJZCI6ImRlYzcxMWVlLTY2NmUtNGIwZi04ZmYyLWM3ZTQzZTlhNTBmOSJ9&listType=2&listTitle=Everything' },
    ],
  }

  useEffect(() => {
    const selectedColorSeason = colorSeasons.find(cs => cs.name.toLowerCase().replace(/ /g, '_') === colorSeason.toLowerCase());

    if (selectedColorSeason) {
      setColorSeason(selectedColorSeason.name);
      setHexcode(selectedColorSeason.colors[0]);
      setPresetHexcodes(selectedColorSeason.colors);
    }
  }, [colorSeason]);

  const updateHexcodeInURL = (newHexcode) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hexcode')) { 
        urlParams.set('hexcode', newHexcode.replace(/^#/, ''));
        window.history.replaceState(null, '', `?${urlParams.toString()}`);
    }
  };

  const handleDefaultDatasetSelect = () => {
    setHexcode(null)
    const options = getOptionsForSeason(colorSeason);
    setResults(options);
    setIsDefaultResults(true)
    setSelectedHexcode('')
  }

  useEffect(() => {
    updateHexcodeInURL(hexcode);
  }, [hexcode]);

  useEffect(() => {
    if (hexcode || searchQuery) {
      if (window.gtag) {
        window.gtag('event', 'search', {
          event_category: 'engagement',
          event_label: searchQuery || hexcode,
        });
      }
      mixpanel.track('web_color_searched', {
        hexcode: hexcode,
      });
      const params = new URLSearchParams({ hexcode: hexcode, query: searchQuery, is_website: true });
      const apiUrl = new URL(`${process.env.REACT_APP_API_URL}/color_search`);
      const token = 'capsule-shopping-1234';
     
      fetch(`${apiUrl}?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => setResults(data))
        .then(console.log(hexcode))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [hexcode, searchQuery]);

  useEffect(() => {
    if (results) {
      results.forEach((item, index) => {
        if (item.image) {
          const img = new Image();
          const separator = item.image.includes('?') ? '&' : '?';
          img.src = item.image.includes('cdn.shopify') ? `${item.image}${separator}height=200` : item.image;
          img.onload = () => {
            setImageLoadingStatus(prevStatus => ({
              ...prevStatus,
              [index]: 'loaded' // Image loaded successfully
            }));
          };
          img.onerror = () => {
            setImageLoadingStatus(prevStatus => ({
              ...prevStatus,
              [index]: 'error' // Image failed to load
            }));
          };
          setImageLoadingStatus(prevStatus => ({
            ...prevStatus,
            [index]: 'loading' // Image is currently loading
          }));
        }
      });
    }
  }, [results]);

  const clearColorSeason = () => {
    setPresetHexcodes([])
    setColorSeason('');
    setSelectedColorSeasonName('');
    setSelectedHexcode('');
    if (!hexcode) {
      setHexcode('#008080');
    } 
    setIsDefaultResults(false);

    // Clear color season from the URL
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('color_season');
    if (urlParams.toString()) {
      window.history.replaceState(null, '', `?${urlParams.toString()}`);
    } else {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }

  const handleTrendingForFallSelect = () => {
    setColorSeason('Trending for Fall');
    setPresetHexcodes(trendingForFallColors);
    setHexcode(trendingForFallColors[0]);
    setSelectedColorSeasonName('Trending for Fall');
  };

  return (
    <>
    <Helmet>
      <title>Color Search</title>
      <meta property="og:title" content="CAPSULE | Color Search" />
    </Helmet>
    <div className="search-container">      
      <div className="input-button-container">
        <div className="color-search search">
            <div className="center-container">
              <div className="defaults-container">
                {!isDefaultResults && !colorSeason && (
                  <div className="color-season-dropdown-container search">
                    <div 
                      className="color-circle" 
                      style={{ 
                        '--hexcode-color': hexcode === '#008080' ? '#FFFFFF' : (hexcode || 'transparent') 
                      }} 
                      onClick={() => setShowColorPicker(!showColorPicker)}
                    >
                        <FontAwesomeIcon icon={faEyeDropper} className="color-picker-icon" />
                    </div>
                    {showColorPicker && (
                      <section className="custom-layout example" >
                        <div className="color-season-dropdown-container" onClick={() => setShowColorSeasonDropdown(false)}>
                          <HexColorPicker color={hexcode} onChange={setCurrentHexcode} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} className="custom-sketch-picker" />
                          <HexColorInput color={hexcode} onChange={setCurrentHexcode} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} className="hexcode-picker custom-sketch-picker"/>
                        </div>
                      </section>
                    )}
                    {!isDefaultResults && (<div className="small-text">Search by Color</div>)}
                  </div>
                )}
                {/* {colorSeason && (
                <div className="default-dataset search default-dataset" onClick={handleDefaultDatasetSelect}>
                  <div className="center-container">
                    <div className="center-container" style={{ '--hexcode-color': '#FFF' }} >
                      <div className="image-emoji-icon" role="img" aria-label="default-sparkle">✨</div>
                    </div>
                    <div className="small-text"></div>
                  </div>
                </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="color-seasons-container">
            {presetHexcodes.length > 0 && (
              <>
                <div className="centered-header">
                  {colorSeason && (
                    <>
                      <div className="color-season-display">
                        {colorSeason}
                      </div>
                      <div className="align-right-container">
                      <button 
                        className="clear-preset-colors" 
                        onClick={() => clearColorSeason()}
                      >
                          <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  </>
                  )}
                </div>
                <div className="preset-colors grid">
                  { !isDefaultResults && (
                    <div className="color-season-dropdown-container search">
                      <div 
                        className="color-circle" 
                        style={{ '--hexcode-color': hexcode === '#008080' ? '#FFFFFF' : (hexcode || 'transparent') }} 
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      >
                      <FontAwesomeIcon icon={faEyeDropper} className="color-picker-icon" />
                      </div>
                      {showColorPicker && (
                        <section className="custom-layout example" >
                          <div className="color-season-dropdown-container" onClick={() => setShowColorSeasonDropdown(false)}>
                            <HexColorPicker color={hexcode} onChange={setCurrentHexcode} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} className="custom-sketch-picker" />
                            <HexColorInput color={hexcode} onChange={setCurrentHexcode} onMouseUp={onDragEnd} onTouchEnd={onDragEnd} className="hexcode-picker custom-sketch-picker"/>
                          </div>
                        </section>
                      )}
                    </div>
                  )}
                  <div className="default-dataset search" onClick={handleDefaultDatasetSelect}>
                    <div className="image-emoji-icon" role="img" aria-label="default-sparkle">✨</div>
                  </div>
                  {presetHexcodes.slice(0, 8).map((presetHexcode, index) => (
                    <div 
                      key={index} 
                      className="preset-color-circle" 
                      style={{ '--preset-color': presetHexcode,  border: selectedHexcode === presetHexcode ? '2px solid black' : 'none'  }} 
                      onClick={() => {
                        setHexcode(presetHexcode);
                        setSelectedHexcode(presetHexcode); // Update the selectedHexcode state
                        setIsDefaultResults(false)
                      }}

                    />
                  ))}

               

  
              </div>
              </>
          )}
          </div>
      {!colorSeason && !selectedColorSeasonName && (
        <div className="deactivated-search">
          <div className="image-search search" onClick={() => setShowColorSeasonDropdown(!showColorSeasonDropdown)}>
            <div className="center-container search">
              <div className="center-container color-circle" style={{ '--hexcode-color': '#FFF' }} >
                <FontAwesomeIcon icon={faUser} className="image-search-icon" />
              </div>
              <div className="small-text">Color Season</div>
            </div>
          </div>

          {showColorSeasonDropdown && (
            <div className="color-season-dropdown"
            onClick={() => setShowColorPicker(false)}>
              {colorSeasons.map((season, index) => (
                <div key={index} className="color-season-section">
                  <div className="color-season-name">{season.name}</div>
                  <div className="color-grid">
                    {season.hexcodes.map((color, idx) => (
                      <div 
                        key={idx} 
                        className="color-square" 
                        style={{ backgroundColor: color }} 
                        onClick={() => handleColorSelect(color, season.name)}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="text-search search" onClick={handleTrendingForFallSelect}>
            <div className="center-container search">
              <div className="color-circle" style={{ '--hexcode-color': '#FFF' }} >
                <FontAwesomeIcon icon={faCoffee} className="image-search-icon" />
              </div>
              <div className="small-text">Trending for Fall</div>
            </div>
          </div>
          <div className="text-search search">
          {/* <div className="coming soon center-container">
            <div className="color-circle color-circle-soon" style={{ '--hexcode-color': '#FFF' }} >
              <FontAwesomeIcon icon={faFootballBall} className="image-search-icon greyed-out" />
              <span className="tooltip">Coming Soon</span>
            </div>
            <div className="small-text">School Spirit</div>
          </div> */}
        </div>

          </div>
        )} 

      </div>
      <div className="input-group"></div>
      {showTooltip && (
        <div className="tooltip-container">
          <span>Psst… We scrape 25K sites every night and are constantly expanding. <br />
          Who would you like us to add next? </span>
          <a href="https://forms.gle/Y82mBXYoqEZwQjBM6" target="_blank" rel="noopener noreferrer">
            <b><u>Tell us here</u></b>
          </a>
          <button onClick={() => setShowTooltip(false)} className="close-tooltip">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    { !isDefaultResults && ( <div className="sort-dropdown align-right">
      <label htmlFor="sortOrder">Sort By: </label>
      <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
        <option value="">Select</option>
        <option value="priceLowToHigh">Price (Low to High)</option>
        <option value="priceHighToLow">Price (High to Low)</option>
      </select>
    </div>
    )}
    <div className="results-container">
    {isDefaultResults && (
      <div className="left-results-container">
        <div className={`color-closet-header-container ${isDefaultResults ? 'visible' : ''}`}>
          Your Color <span className="mobile-hide-br"><br /></span> Closet
        </div>
        <div className="color-closet-sub-header-container">
          <div>We pulled some favs to get you started.</div>
          <div>Tap any color to search more looks for you!</div>
        </div>
      </div>
    )}
        <div className="results-grid">
          {Array.isArray(sortedResults) && sortedResults.map((item, index) => (
            <div key={index} className="result-item">
              <a 
                href={item.url} 
                className="clickable-link" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={() => {
                  mixpanel.track('web_link_clicked', {
                    url: item.url,
                    retailer: item.retailer,
                    title: item.title,
                    price: item.price,
                    position: index + 1
                  });
                }}
              >
               {imageLoadingStatus[index] === 'loaded' ? (
                <>
                  <img src={item.image} alt={`Item ${index}`} className="result-image" />
                  <p className="no-margin retailer">{item.retailer}</p>
                  <p className="no-margin truncate-title title">{item.title}</p>
                  <p className="no-margin price">{item.price}</p>
                </>
              ) : (
                <div className="pulse-placeholder">
                  <div className="pulse"></div>
                  <div className="pulse-line"></div>
                  <div className="pulse-line"></div>
                  <div className="pulse-line"></div>
                </div>
              )}
            </a>
          </div>
          ))}
        </div>
      </div>  
    </div>
    </>
  );
}

export default Search;