import React, { useEffect } from 'react';
import './Contact.css';

function Contact() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="contact-container">
      <div data-tf-live="01HKBK4AX5MBN04S34FWDYMJR5"></div>
    </div>
  );
}

export default Contact;


