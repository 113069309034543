import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import './Analysis.css';
import logo from './logo.png';
import preview from './preview.png';
import preview3 from './preview3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import mixpanel from 'mixpanel-browser';
import heic2any from "heic2any";

import lightSpringCollageGreenTee from './images/collages/light_spring/light_spring_collage_green_tee.png'
import lightSpringCollagePants from './images/collages/light_spring/light_spring_collage_pants.png'
import lightSpringCollageSneakers from './images/collages/light_spring/light_spring_collage_sneakers.png'
import lightSpringCollageSweater from './images/collages/light_spring/light_spring_collage_sweater.png'
import lightSpringCollageSweatshirt from './images/collages/light_spring/light_spring_collage_sweatshirt.png'

import brightSpringCollageAdidas from './images/collages/bright_spring/bright_spring_collage_adidas.png'
import brightSpringCollageGreenDress from './images/collages/bright_spring/bright_spring_collage_green_dress.png'
import brightSpringCollagNecklace from './images/collages/bright_spring/bright_spring_collage_necklace.png'
import brightSpringCollageRainbowPants from './images/collages/bright_spring/bright_spring_collage_rainbow_pants.png'
import brightSpringCollageSweater from './images/collages/bright_spring/bright_spring_collage_sweater.png'

import warmSpringCollageBodysuit from './images/collages/warm_spring/warm_spring_collage_bodysuit.png'
import warmSpringCollageBoots from './images/collages/warm_spring/warm_spring_collage_boots.png'
import warmSpringCollageCowboyHat from './images/collages/warm_spring/warm_spring_collage_cowboy_hat.png'
import warmSpringCollageDress from './images/collages/warm_spring/warm_spring_collage_dress.png'
import warmSpringCollagePurse from './images/collages/warm_spring/warm_spring_collage_purse.png'

import lightSummerCollageDress from './images/collages/light_summer/light_summer_collage_dress.png'
import lightSummerCollageGlasses from './images/collages/light_summer/light_summer_collage_glasses.png'
import lightSummerCollageHat from './images/collages/light_summer/light_summer_collage_hat.png'
import lightSummerCollageJacket from './images/collages/light_summer/light_summer_collage_jacket.png'
import lightSummerCollagePants from './images/collages/light_summer/light_summer_collage_pants.png'

import softSummerCollageAdidas from './images/collages/soft_summer/soft_summer_collage_adidas.png'
import softSummerCollageGreenSweatshirt from './images/collages/soft_summer/soft_summer_collage_green_sweatshirt.png'
import softSummerCollageJacket from './images/collages/soft_summer/soft_summer_collage_jacket.png'
import softSummerCollagePurse from './images/collages/soft_summer/soft_summer_collage_purse.png'
import softSummerCollageSunglasses from './images/collages/soft_summer/soft_summer_collage_sunglasses.png'

import coolSummerCollageGlasses from './images/collages/cool_summer/cool_summer_collage_glasses.png'
import coolSummerCollageJacket from './images/collages/cool_summer/cool_summer_collage_jacket.png'
import coolSummerCollagePants from './images/collages/cool_summer/cool_summer_collage_pants.png'
import coolSummerCollageShoes from './images/collages/cool_summer/cool_summer_collage_shoes.png'
import coolSummerCollageTop from './images/collages/cool_summer/cool_summer_collage_top.png'

import warmAutumnCollageDress from './images/collages/warm_autumn/warm_autumn_college_dress.png';
import warmAutumnCollagePants from './images/collages/warm_autumn/warm_autumn_college_pants.png'
import warmAutumnCollageShoes from './images/collages/warm_autumn/warm_autumn_college_shoes.png'
import warmAutumnCollageTop from './images/collages/warm_autumn/warm_autumn_college_top.png'
import warmAutumnCollagePurse from './images/collages/warm_autumn/warm_autumn_college_purse.png'

import softAutumnBodysuit from './images/collages/soft_autumn/soft_autumn_collage_bodysuit.png';
import softAutumnBoots from './images/collages/soft_autumn/soft_autumn_collage_boots.png';
import softAutumnDress from './images/collages/soft_autumn/soft_autumn_collage_dress.png';
import softAutumnPurse from './images/collages/soft_autumn/soft_autumn_collage_purse.png';
import softAutumnSweater from './images/collages/soft_autumn/soft_autumn_collage_sweater.png';

import productShoe from './product_shoe.png'
import productSweater from './product_sweater.png'
import productLongSweater from './product_long_sweater.png'
import productHat from './product_hat.png'
import productJacket from './product_jacket.png'

import coolWinterCollageBeanie from './images/collages/cool_winter/cool_winter_collage_beanie.png';
import coolWinterCollageBoots from './images/collages/cool_winter/cool_winter_collage_boots.png';
import coolWinterCollageDress from './images/collages/cool_winter/cool_winter_collage_dress.png';
import coolWinterCollageHalter from './images/collages/cool_winter/cool_winter_collage_halter.png';
import coolWinterCollageJacket from './images/collages/cool_winter/cool_winter_collage_jacket.png';

import clearWinterCollageBrazil from './images/collages/clear_winter/clear_winter_collage_brazil.png';
import clearWinterCollageDress from './images/collages/clear_winter/clear_winter_collage_dress.png';
import clearWinterCollagePinkTop from './images/collages/clear_winter/clear_winter_collage_pink_top.png';
import clearWinterCollageScarf from './images/collages/clear_winter/clear_winter_collage_scarf.png';
import clearWinterCollageSneakers from './images/collages/clear_winter/clear_winter_collage_sneakers.png';

import deepWinterCollageDress from './images/collages/deep_winter/deep_winter_collage_dress.png'
import deepWinterCollagePants from './images/collages/deep_winter/deep_winter_collage_pants.png'
import deepWinterCollagePurse from './images/collages/deep_winter/deep_winter_collage_purse.png'
import deepWinterCollageShoes from './images/collages/deep_winter/deep_winter_collage_shoes.png'
import deepWinterCollageYellowTop from './images/collages/deep_winter/deep_winter_collage_yellow_top.png'

function LandingPage({ getRootProps, getInputProps, isDragActive, getDropzoneText }) {
  return (
    <div className="split-container">
      <div className="left-container">
        <div className="season-header" style={{ textAlign: 'center', marginBottom: '10px' }}>
          <span style={{ display: 'block' }}>Your Color</span>
          <span style={{ display: 'block' }}>Closet</span>
        </div>
        <div className="season-sub-header">
          Upload your selfie, get your best colors, shop the perfect fits!
        </div>
        <div className="dropzone-container">
          <div 
            {...getRootProps()} 
            className="dropzone-component" 
            style={{ 
              border: '2px dotted #cccccc', 
              padding: '10px', 
              textAlign: 'center',
              backgroundColor: isDragActive ? '#f0f0f0' : 'transparent'
            }}
          >
            <input {...getInputProps()} />
            <div className="camera-icon-container">
              <FontAwesomeIcon icon={faCamera} className="camera-icon" />
            </div>
            <div className="dropzone-text">
              {getDropzoneText()}
            </div>
          </div>
          <a 
            href="/color-search" 
            style={{ display: 'block', textAlign: 'center', margin: '20px 0', color: '#000', fontSize: '0.8em' }}
          >
            Know your color season? <strong>Start shopping</strong>
          </a>
        </div>
        <div className="powered-by" style={{ textAlign: 'center' }}>
          <span>Powered by</span>
          <span><img className="logo" src={logo} alt="Logo" /></span>
          <div className="powered-by-about">
            <div>the first visual fashion search engine:</div>
            <div>shop your pics, link, and colors</div>
          </div>
        </div>
      </div>
      <div className="right-container">
        <img className="preview-image" src={preview3} alt="Preview" />
      </div>
    </div>
  );
}

function LoadingView({ selfieUrl }) {
  return (
    <div className="scanner-overlay">
      <div className="scanner">
        {/* <div className="loading-header">Your Best Colors...</div> */}
        {selfieUrl && (
          <img src={selfieUrl} alt="Selfie" className="selfie-preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        )}
        <div className="scanner-line"></div>
      </div>
    </div>
  );
}

function formatSeason(season) {
  return season.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

function ResultsView({ data, presetColors, setSelfieUrl, selfieUrl }) {
  const seasonProductMapping = {
    LIGHT_SPRING: [lightSpringCollageSweatshirt, lightSpringCollageGreenTee, lightSpringCollageSneakers, lightSpringCollageSweater, lightSpringCollagePants],
    BRIGHT_SPRING: [brightSpringCollageSweater, brightSpringCollagNecklace, brightSpringCollageAdidas, brightSpringCollageGreenDress, brightSpringCollageRainbowPants],
    WARM_SPRING: [warmSpringCollageCowboyHat, warmSpringCollageBodysuit, warmSpringCollageBoots, warmSpringCollageDress, warmSpringCollagePurse],
    LIGHT_SUMMER: [lightSummerCollageJacket, lightSummerCollageHat, lightSummerCollageGlasses, lightSummerCollageDress, lightSummerCollagePants],
    COOL_SUMMER: [coolSummerCollageJacket, coolSummerCollageGlasses, coolSummerCollageTop, coolSummerCollagePants,coolSummerCollageShoes],
    SOFT_SUMMER: [softSummerCollageJacket, softSummerCollageAdidas, softSummerCollagePurse, softSummerCollageSunglasses, softSummerCollageGreenSweatshirt ],
    SOFT_AUTUMN: [softAutumnDress, softAutumnSweater, softAutumnPurse, softAutumnBoots, softAutumnBodysuit],
    WARM_AUTUMN: [warmAutumnCollageDress, warmAutumnCollagePurse, warmAutumnCollageTop, warmAutumnCollagePants, warmAutumnCollageShoes ],
    DEEP_AUTUMN: [productJacket, productHat, productSweater, productLongSweater, productShoe],
    CLEAR_WINTER: [clearWinterCollagePinkTop, clearWinterCollageBrazil, clearWinterCollageSneakers, clearWinterCollageDress, clearWinterCollageScarf],
    COOL_WINTER: [coolWinterCollageDress, coolWinterCollageBoots, coolWinterCollageJacket, coolWinterCollageBeanie, coolWinterCollageHalter],
    DEEP_WINTER: [deepWinterCollageDress, deepWinterCollageShoes, deepWinterCollageYellowTop, deepWinterCollagePants, deepWinterCollagePurse]
  };

  const products = seasonProductMapping[data.color_season.toUpperCase()] || [];

  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const horoscopes = {
    LIGHT_SPRING: "Your style is fresh, soft, and full of breezy energy. You’re drawn to delicate pastels, light florals, and soft textures like airy knits and flowy dresses. Your closet has a playful and cheerful feel, featuring mint greens, soft peaches, and sky blues. It’s all about easy, relaxed vibes with a bright, sunny twist.",
    BRIGHT_SPRING: "You have a vibrant and lively style, full of bold colors and striking patterns. Your wardrobe is a celebration of life, with bright yellows, vivid greens, and electric blues. You love to stand out and make a statement with your fashion choices.",
    WARM_SPRING: "Your style is bright, lively, and full of warmth. You’re drawn to sun-kissed colors like golden yellows, coral oranges, and fresh greens. Your closet radiates energy and optimism, featuring playful, fun pieces that reflect your upbeat and vibrant personality. You love mixing bold shades with cheerful, effortless looks that capture the essence of a sunny day.",
    LIGHT_SUMMER: "Your style is soft, airy, and fresh. You’re drawn to pastel shades like lavender, icy blue, and soft pink, with light fabrics that feel effortless. Your closet reflects a delicate, breezy energy, combining soft textures and muted colors for a look that's both calming and refreshing. You radiate a gentle, serene vibe that’s perfect for an easygoing, graceful style.",
    COOL_SUMMER: "Your style is all about soft, cool tones and effortless elegance. You’re drawn to shades like icy blues, deep purples, and cool reds, with sleek and sophisticated cuts. Your closet reflects your calm, polished energy, mixing soft textures and flattering silhouettes. You radiate a serene confidence, with looks that feel both refreshing and refined.",
    SOFT_SUMMER: "Your style is gentle, muted, and effortlessly chic. You’re drawn to soft greens, dusty pinks, and delicate pastels that create a calm, soothing look. Your closet reflects a relaxed, understated elegance with pieces that feel comfortable yet polished. You love blending soft colors with simple, refined silhouettes, creating a look that’s timeless and effortlessly cool.",
    SOFT_AUTUMN: "Your style is all about warm, muted tones and easygoing sophistication. Think cozy knits, soft denim, earthy neutrals, and warm pastels like caramel, peach, and dusty blue. Your look is effortless and approachable, reflecting your natural warmth and grounded vibe.",
    WARM_AUTUMN: "Your vibe is rich, earthy, and full of warmth. You’re drawn to golden yellows, deep reds, and olive greens, with natural, cozy textures. Your closet reflects your grounded and inviting energy, with warm tones and relaxed silhouettes that create an effortless, yet polished look.",
    DEEP_AUTUMN: "Your vibe is bold and grounded. You’re confident, embracing life with depth and warmth. Your style? Think earthy tones, deep jewel colors, and textured fabrics that mirror your strong, natural presence. You radiate confidence and warmth, drawing people in with your magnetic, grounded energy.",
    CLEAR_WINTER: "Your vibe is bold, bright, and full of energy. You’re drawn to vibrant colors like fuchsia, electric blue, and lime green, with playful, eye-catching pieces. Your closet reflects your confident and dynamic spirit, mixing sharp contrasts and sleek silhouettes for a striking, fresh look.",
    COOL_WINTER: "Your vibe is sleek, cool, and effortlessly polished. You’re drawn to rich purples, deep blues, and icy tones, with clean lines and refined textures. Your closet reflects your calm, confident energy, creating an elegant, yet understated look.",
    DEEP_WINTER: "Your style is bold, powerful, and dramatic. You love rich, deep colors like emerald green, purple, and electric blue, with sharp contrasts like bright yellow. Your look is sleek and confident, full of striking combinations that command attention and reflect your strong, mysterious energy."
  };
 
  return (
    <>
    <div className="results-big-header">Your Color</div>
    <div className="results-big-header">Closet</div>
    <div className="overlay-container">
      <div className="overlay">
        {products.map((product, index) => (
          <div className={`product-image-container position-${index + 1}`}>
            <img key={index} className={`product-image`} src={product} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="results-container">
      <div className="selfie-column">
        <img className="selfie-img" src={selfieUrl} alt="Selfie" style={{ borderRadius: '10px' }} />
      </div>
      <div className="products-column">
        {data && (
          <>
            <div className="color-results-column">
              <div className="color-result-leadin">You are a...</div>
              <div className="color-result-header">{formatSeason(data.color_season)}</div>
              <div className="color-result-colors">
                <div style={{ display: 'flex', gap: '10px' }}>
                  {presetColors.map((hex, index) => (
                    <div key={index} style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: hex,
                      border: '.5px solid black'
                    }}></div>
                  ))}
                </div>
              </div>
            </div>
            <div className="horoscope-container">
              {horoscopes[data.color_season.toUpperCase()]}
            </div>
            <button 
              className="big-white-button" 
              style={{ cursor: 'pointer' }} 
              onClick={() => {
                window.location.href = `/color-search?color_season=${data.color_season.toLowerCase()}`;
              }}
            >
              Shop Your Colors
            </button>
            {/* <div className="results-details">
              <div className="results-header">A bit behind the magic...🪄 </div>
              <div className="results-sub-header">Your color season is derived from:</div>
              <div className="results-attribute">Hair Color: {data.hair_color}</div>
              <div className="results-attribute">Eye Color: {data.eye_color}</div>
              <div className="results-attribute">Hair Hexcode: <span style={{ background: data.hair_hexcode}}>{data.hair_hexcode}</span></div>
              <div className="results-attribute">Eye Hexcode: <span style={{ background: data.eye_hexcode}}>{data.eye_hexcode}</span></div>
              <div className="results-attribute">Skin Hexcode: <span style={{ background: data.skin_hexcode}}>{data.skin_hexcode}</span></div>
              <div className="results-attribute">Skin Undertones: {data.skin_undertones}</div>
              <div className="results-attribute">Skin Tone: {data.skin_tone}</div>
              <div className="results-attribute">Skin Tint: {data.skin_tint}</div>
              <div className="results-attribute">Skin Hue: {data.skin_muted_or_saturated}</div>
            </div> */}



            
            <div className="results-reset">
              <div>Do you want to try another photo? 
                <a 
                  style={{ cursor: 'pointer', textDecoration: 'none', marginLeft: '10px' }} 
                  onClick={() => setSelfieUrl('')}
                  href="#"
                >
                  <strong>Reset</strong>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
      </div>
    </div>
    <div className="share-button floating-share-button" onClick={() => {
      navigator.clipboard.writeText(window.location.href)
        .then(() => {
          setShowCopyNotification(true);
          setTimeout(() => setShowCopyNotification(false), 4000);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }}>
      Share with friends
    </div>
    {showCopyNotification && (
        <div style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: 'blue',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 1000
        }}>
          URL added to clipboard
        </div>
        )}
    </>
  );
}

function Analysis() {
  const [data, setData] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [presetColors, setPresetColors] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    if (loading) {
      document.body.style.backgroundColor = 'black';
      document.getElementById('navigation-container').style.display = 'none';
      document.getElementById('footer-container').style.display = 'none';
    } else if (!selfieUrl && !loading) {
      document.body.style.backgroundColor = '';
      document.getElementById('navigation-container').style.display = 'flex';
      document.getElementById('footer-container').style.display = 'none';     
    }
  }, [loading, selfieUrl]);

  const SEASON_HEX_CODES = {
    LIGHT_SPRING: ["#ee6879", "#f5ad93", "#fff1a5", "#61cb65", "#c7f1b7", "#96d6d2", "#9fe7f5", "#96c8fc"],
    BRIGHT_SPRING: ["#ed4847", "#f3a6a4", "#f5bedb", "#f7b640", "#fee83c", "#65d34e", "#5fd5c5", "#304098"],
    WARM_SPRING: ["#489a46", "#fd836a", "#bb4845", "#f7b640", "#ee6e47", "#e27f4d", "#84d46b", "#52b8ac"],
    LIGHT_SUMMER: ["#a36872", "#dbcef7", "#b5a1ce", "#f9dcf4", "#89e2b4", "#90b7fb", "#b5f4fb", "#4976bf"],
    COOL_SUMMER: ["#7c4171", "#bd8ec4", "#973055", "#d13e4d", "#183779", "#91c4ea", "#65e4dd", "#4ba685"],
    SOFT_SUMMER: ["#a06b94", "#c5787f", "#e5699b", "#828fed", "#fff6b7", "#4fad83", "#6f94b6", "#9cccc7"],
    SOFT_AUTUMN: ["#a7552e", "#e8713a", "#ecd761", "#ddc1b6", "#df867f", "#326493", "#6cbebe", "#9579a7"],
    WARM_AUTUMN: ["#9c2d35", "#c83a25", "#ee6030", "#e8c12e", "#844221", "#194234", "#636d0d", "#80b45d"],
    DEEP_AUTUMN: ["#be3b38", "#f49931", "#e4432d", "#fcd232", "#5f2d16", "#164a74", "#1a3e01", "#3e1a3a"],
    CLEAR_WINTER: ["#622b86", "#c53c5a", "#ec5095", "#e9e0fd", "#faf736", "#57b867", "#2b85c4", "#6befdc"],
    COOL_WINTER: ["#872b5a", "#aa9cbd", "#6f4786", "#252b5e", "#1a57be", "#e1f5fe", "#1f565e", "#3293cd"],
    DEEP_WINTER: ["#5d2f79", "#973055", "#324261", "#cd4486", "#fff264", "#1a2682", "#c73d57", "#1a4850"]
  };

  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const isSmallScreen = () => window.innerWidth <= 768;

  const getDropzoneText = () => (isMobile() || isSmallScreen()) ? 'Tap to upload a selfie' : 'Drag & drop a selfie or upload here';
  
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
  
    if (file.type === "image/heic" || file.type === "image/heif") {
      // Convert HEIC/HEIF to JPEG
      heic2any({ blob: file, toType: "image/jpeg" })
        .then((convertedBlob) => {
          const reader = new FileReader();
          reader.onload = () => {
            setSelfieUrl(reader.result);
  
            if (window.gtag) {
              window.gtag('event', 'upload', {
                event_category: 'User Interaction',
                event_label: 'Photo Uploaded'
              });
            }
  
            console.log("Uploading file:", file);
          };
          reader.readAsDataURL(convertedBlob);
        })
        .catch((error) => {
          console.error("Error converting HEIC file:", error);
        });
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        setSelfieUrl(reader.result);
  
        if (window.gtag) {
          window.gtag('event', 'upload', {
            event_category: 'User Interaction',
            event_label: 'Photo Uploaded'
          });
        }
  
        console.log("Uploading file:", file);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  function formatSeason(season) {
    return season.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  useEffect(() => {
    if (selfieUrl && data) {
      // Track color season view event
      if (window.gtag) {
        window.gtag('event', 'view', {
          event_category: 'User Interaction',
          event_label: 'Color Season Displayed',
          value: data.color_season.toLowerCase()
        });
      }
      mixpanel.track("web_color_season_displayed", {
        "color_season": data.color_season
      });
    }
  }, [selfieUrl, data]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const analyzeColorSeasons = async () => {
    setLoading(true);
    const base64Image = selfieUrl.split(',')[1].replace(/\s/g, '');
    const url = new URL(`${process.env.REACT_APP_API_URL}/color_seasons/analyze`);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // 5000 ms timeout
    const payload = { selfie: base64Image, user_id: 1 };

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
    
    .then(response => response.json())
    .then(data => {
      setLoading(false);
      const colors = SEASON_HEX_CODES[data.color_season.toUpperCase()];
      setData(data);
      setPresetColors(colors);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (selfieUrl) {
      mixpanel.track("web_color_season_selfie_uploaded");
      analyzeColorSeasons();
    }
  }, [selfieUrl]);

  return (
    <>
      <Helmet>
        <title>Your Color Closet</title>
        <meta property="og:title" content="CAPSULE | Your Color Closet" />
        <meta property="og:image" content="URL_TO_YOUR_NEW_IMAGE" />
      </Helmet>
    
      <div className="season-container">
        {!selfieUrl && !loading && (
          <LandingPage 
            getRootProps={getRootProps} 
            getInputProps={getInputProps} 
            isDragActive={isDragActive} 
            getDropzoneText={getDropzoneText} 
          />
        )}

        {selfieUrl && loading && <LoadingView selfieUrl={selfieUrl} />}

        {selfieUrl && !loading && data && (
          <ResultsView 
            data={data} 
            presetColors={presetColors} 
            setSelfieUrl={setSelfieUrl} 
            selfieUrl={selfieUrl}
          />
        )}
      </div>
    </>
  );
}

export default Analysis;